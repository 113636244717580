<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="suchen"
            single-line
            hide-details
          />
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="candidates"
            :search="search"
          >
            <template v-slot:item.b_Recusal="{ item }">
              <v-chip
                :color="getColor(item.b_Recusal)"
                dark
                @click="selectCandidateToRecusal(item.i_AddressRoleID)"
              >
                {{ (item.b_Recusal) ? 'Ja' : 'Nein' }}
              </v-chip>
            </template>
            <template v-slot:item.s_Reason="{ item }">
              <v-icon
                color="primary"
                @click="selectCandidateToRecusal(item.i_AddressRoleID)"
              >
                mdi-note-edit
              </v-icon>
            </template>
          </v-data-table>

          <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
          >
            {{ snackText }}

            <template v-slot:action="{ attrs }">
              <v-btn
                v-bind="attrs"
                text
                @click="snack = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-card-text>
      </base-card>
      <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Begründung</span>
          </v-card-title>

          <v-card-text>
            <v-textarea
              v-model="selectedCandidate.s_Reason"
              :label="selectedCandidate.s_Lastname + ' - Ausstandsbegehren'"
              autofocus
              outlined
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              v-if="selectedCandidate.b_Recusal"
              color="red darken-1"
              text
              @click="dialogDelete = true; dialog = false"
            >
              Delete
            </v-btn>
            <v-btn
              color="success darken-1"
              text
              :disabled="disableSaveRecusal"
              @click="setRecusal('create')"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="setRecusal('delete')">OK</v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
  import { get, post } from '../../../worker/worker-api'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'Recusal',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Ausstandsbegehren',
    },
    components: {
    },
    data () {
      return {
        dialog: false,
        dialogDelete: false,
        selectedCandidate: {
          i_AddressRoleID: null,
          s_Lastname: null,
          s_Firstname: null,
          b_Recusal: null,
          s_Reason: null,
          i_RecusalID: null,
        },

        search: '',
        snack: false,
        reasonDialog: false,
        snackColor: '',
        snackText: '',
        pagination: {},
        headers: [
          {
            text: 'Nachname',
            align: 'start',
            sortable: false,
            value: 's_Lastname',
          },
          { text: 'Vorname', value: 's_Firstname' },
          { text: 'Ausstandsbegehren', value: 'b_Recusal' },
          { text: 'Begründung', value: 's_Reason' },
        ],
        candidates: [],
      }
    },
    computed: {
      ...mapGetters(['getPlanningPeriodID', 'getCurrentExamEvent']),
      disableSaveRecusal () {
        if (this.selectedCandidate === null) return true
        if (this.selectedCandidate.s_Reason === null) return true
        if (this.selectedCandidate.s_Reason.trim().length > 0) return false
        return true
      },
    },
    watch: {
    },
    beforeMount () {
      this.getTeacherCandidates()
    },
    methods: {
      ...mapActions(['signOut']),
      getTeacherCandidates () {
        const teacherID = this.$smt.getAddressRoleID(this.$route.meta.role)
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/period/${this.getCurrentExamEvent.i_PlanningPeriodID}/candidates`, {
        })
          .then(response => {
            if (response.status === 401) {
              // this.signOut()
              // this.$smt.logout()
              return
            }
            this.candidates = response
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getColor (recusal) {
        if (recusal) return 'red'
        else return 'green'
      },
      save () {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = 'Data saved'
      },
      cancel () {
        this.snack = true
        this.snackColor = 'error'
        this.snackText = 'Canceled'
      },
      open () {
        this.snack = true
        this.snackColor = 'info'
        this.snackText = 'Dialog opened'
      },

      deleteItemConfirm () {
        this.desserts.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.selectedCandidate = {
            i_AddressRoleID: null,
            s_Lastname: null,
            s_Firstname: null,
            b_Recusal: null,
            s_Reason: null,
            i_RecusalID: null,
          }
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.selectedCandidate = Object.assign({})
        })
      },
      selectCandidateToRecusal (Ai_AddressRoleID) {
        const Li_Index = this.candidates.findIndex(candidate => candidate.i_AddressRoleID === Ai_AddressRoleID)
        this.selectedCandidate = this.candidates[Li_Index]
        this.dialog = true
      },
      setRecusal (As_Action) {
        const teacherID = this.$smt.getAddressRoleID(this.$route.meta.role)
        const Li_Index = this.candidates.findIndex(candidate => candidate.i_AddressRoleID === this.selectedCandidate.i_AddressRoleID)
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/recusal`, {
          i_CandidateAddressRoleID: this.selectedCandidate.i_AddressRoleID,
          s_Reason: this.selectedCandidate.s_Reason,
          s_Action: As_Action,
        })
          .then(response => {
            this.editMode = false
            if (response.status === 401) {
              // this.signOut()
              // this.$smt.logout()
              return
            }
            this.closeDelete()
            this.close()
            this.candidates[Li_Index].b_Recusal = !this.candidates[Li_Index].b_Recusal
            this.requestSent = true
            this.responseMessage = 'Ausstandsgebehren gesendet'
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
